import { useTranslation } from "react-i18next";
import FileType from "../FileType.tsx";
import { LanguageEnum } from "./LanguageEnum.tsx";

export enum FileTypeEnum {
  emission_factor = "emission_factor",
  emission_factor_locations = "emission_factor_locations", //複数拠点
  energy_usage = "energy_usage",
  energy_usage_locations = "energy_usage_locations", //複数拠点
  energy_usage_multi_months = "energy_usage_multi_months", // 複数年月
  energy_usage_multi_locations_months = "energy_usage_multi_locations_months", //複数拠点複数年月
  master = "master",
  base_unit = "base_unit",
  activity_amount = "activity_amount",
  pcf_certification_period = "pcf_certification_period",
  property_info = "property_info",
  manual_organization = "manual_organization",
  manual_product = "manual_product",
}

export const FileTypes_Ja: string[] = [
  FileTypeEnum.emission_factor,
  FileTypeEnum.emission_factor_locations,
  FileTypeEnum.energy_usage,
  FileTypeEnum.energy_usage_locations,
  FileTypeEnum.energy_usage_multi_months,
  FileTypeEnum.energy_usage_multi_locations_months,
  FileTypeEnum.master,
  FileTypeEnum.base_unit,
  FileTypeEnum.activity_amount,
  FileTypeEnum.pcf_certification_period,
  FileTypeEnum.property_info,
];

export const FilesType = () => {
  const { t } = useTranslation();
  const CompanyFileType: FileType[] = [
    {
      type: FileTypeEnum.emission_factor,
      title: t("type.fileType.emissionFactor"),
      disp: true,
      dispLan: [],
    },
    {
      type: FileTypeEnum.energy_usage,
      title: t("type.fileType.energyUsage"),
      disp: true,
      dispLan: [],
    },
    {
      type: FileTypeEnum.energy_usage_multi_months,
      title: t("type.fileType.energyUsageMultiMonths"),
      disp: true,
      dispLan: [],
    },
  ];

  const CompanyLocationsFileType: FileType[] = [
    {
      type: FileTypeEnum.emission_factor_locations,
      title: t("type.fileType.emissionFactorLocations"),
      disp: true,
      dispLan: [],
    },
    {
      type: FileTypeEnum.energy_usage_locations,
      title: t("type.fileType.energyUsageLocations"),
      disp: true,
      dispLan: [],
    },
    {
      type: FileTypeEnum.energy_usage_multi_locations_months,
      title: t("type.fileType.energyUsageMultiLocationsMonths"),
      disp: true,
      dispLan: [LanguageEnum.ja],
    },
  ];

  const ProductFileType: FileType[] = [
    {
      type: FileTypeEnum.master,
      title: t("type.fileType.master"),
      disp: true,
      dispLan: [],
    },
    {
      type: FileTypeEnum.base_unit,
      title: t("type.fileType.baseUnit"),
      disp: true,
      dispLan: [],
    },
    {
      type: FileTypeEnum.activity_amount,
      title: t("type.fileType.activityAmount"),
      disp: true,
      dispLan: [],
    },
    {
      type: FileTypeEnum.pcf_certification_period,
      title: t("type.fileType.pcfCertificationPeriod"),
      disp: true,
      dispLan: [],
    },
  ];
  const EnergySavingFileType = [
    {
      type: FileTypeEnum.property_info,
      title: t("type.fileType.propertyInfo"),
      disp: true,
      dispLan: [LanguageEnum.ja],
    },
  ];

  return {
    CompanyFileType,
    CompanyLocationsFileType,
    ProductFileType,
    EnergySavingFileType,
  };
};
