import React, { useState, useContext } from "react";
import IconImage from "@mui/icons-material/Download";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import SideMenusType from "../../../types/SideMenus.tsx";
import classes from "./ReportField.module.scss";
import AppContext from "../../../contexts/AppContext.tsx";
import DownloadButton from "../../parts/Button/DownloadButton.tsx";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useDatamartFile } from "../../../hooks/useDatamartFile.tsx";
import CommonUtils from "../../../common/utils/CommonUtils.tsx";
import { useTranslation } from "react-i18next";
import { ReportFilesEnum } from "../../../types/enum/ReportFilesEnum.tsx";
import { SideMenuEnum } from "../../../types/enum/SideMenuEnum.tsx";
import UserContext from "../../../contexts/UserContext.tsx";
import { UserInfo } from "../../../types/response/UserInfoResponse.tsx";

const ReportField = () => {
  const { ReportOutputItems } = SideMenusType();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [isDownLoading, setDownloading] = useState(false);
  const [fileType, setFileType] = useState("");
  const appContext = useContext(AppContext);
  const userContext = useContext(UserContext);
  const userInfo = userContext?.userInfo as UserInfo;
  const { organizationFiles, summaryFiles, productFiles, equipmentFiles } =
    useDatamartFile();

  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleConfirmExe = () => {
    organizationFiles(fileType);
    setDownloading(false);
    handleConfirmClose();
  };
  const handleConfirmClose = () => {
    setDownloading(false);
    setConfirmOpen(false);
  };

  const getPDFFileName = () => {
    if (!appContext?.sideItem) return "";
    const pageName = ReportOutputItems.find((value) => {
      return value.key === appContext?.sideItem;
    })?.pageName;
    if (pageName) {
      const date = CommonUtils.formatDayYYYYMMDDhhmmss(new Date());
      return `EcoNiPass_${pageName}_${date}.pdf`;
    }
    return "";
  };

  // PDFダウンロード処理
  const pdfDownload = async () => {
    if (appContext?.pdfDownloadRef.current) {
      const element = appContext?.pdfDownloadRef.current;
      const canvas = await html2canvas(element, {
        scale: 2,
        useCORS: true,
      });
      const dataUrl = canvas.toDataURL("image/png");

      const pdf = new jsPDF();
      const imgProps = pdf.getImageProperties(dataUrl);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      pdf.addImage(dataUrl, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save(getPDFFileName());
    }
    setDownloading(false);
    handleClose();
  };

  // ダウンロード処理
  const handleDownload = async (event) => {
    if (isDownLoading) return;
    setDownloading(true);
    const type = event.currentTarget.value;
    if (type === ReportFilesEnum.pdf) {
      pdfDownload();
    } else {
      switch (appContext?.sideItem) {
        case SideMenuEnum.company:
          if (
            type === ReportFilesEnum.energy_saving ||
            type === ReportFilesEnum.registration_data
          ) {
            setFileType(type);
            handleClose();
            return setConfirmOpen(true);
          }
          organizationFiles(type);
          break;
        case SideMenuEnum.product:
          productFiles(type);
          break;
        case SideMenuEnum.summary:
          summaryFiles(type);
          break;
        case SideMenuEnum.facility:
          equipmentFiles(type);
          break;
      }
      setDownloading(false);
      handleClose();
    }
  };
  //帳票出力コンポーネント作成
  const dispReportContent = () => {
    const sideItem = appContext?.sideItem || "";
    const reportItem = ReportOutputItems.find((value) => {
      return value.key === sideItem;
    });
    return (
      <>
        {reportItem &&
          reportItem.reports.map((value, index) => {
            if (
              value.dispLan.length === 0 ||
              value.dispLan.includes(userInfo.language)
            ) {
              return (
                <ListItem key={index} className={classes.dataList}>
                  <Grid container spacing={2} className={classes.title}>
                    <Grid item xs={8}>
                      {value.disabled && (
                        <Typography
                          variant="body1"
                          component="div"
                          sx={{ color: "#bdbdbd" }}
                          className={classes.dialogListItem}
                        >
                          {`${value.title}\n${t(
                            "type.sideMenu.reportOutputItems.disabled"
                          )}`}
                        </Typography>
                      )}
                      {!value.disabled && (
                        <Typography
                          variant="body1"
                          component="div"
                          sx={{ color: "#000" }}
                          className={classes.dialogListItem}
                        >
                          {value.title}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={4}>
                      <DownloadButton
                        label="download"
                        className={classes.iconButton}
                        value={value.type}
                        handleClick={handleDownload}
                        disabled={value.disabled}
                      />
                    </Grid>
                  </Grid>
                </ListItem>
              );
            }
          })}
      </>
    );
  };

  return (
    <>
      <IconButton className={classes.iconButton} onClick={handleClick}>
        <IconImage className={classes.iconImage} />
        <Typography
          variant="body1"
          component="div"
          sx={{ flexGrow: 1 }}
          className={classes.iconTitle}
        >
          {t("header.report")}
        </Typography>
      </IconButton>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="report-dialog"
        className={classes.dialog}
        fullWidth={true}
      >
        <DialogTitle id="report-dialog" className={classes.dialogTitle}>
          {t("header.reportDialogAnnounce")}
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <List>{dispReportContent()}</List>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button className={classes.dialogCloseButton} onClick={handleClose}>
            {t("header.reportDialogClose")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={confirmOpen}
        onClose={handleConfirmClose}
        aria-labelledby="report-confirm-dialog"
        className={classes.dialog}
        fullWidth={true}
      >
        <DialogContent className={classes.dialogContent}>
          <div
            dangerouslySetInnerHTML={{
              __html: t("header.reportConfirmAnnounce"),
            }}
          />
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            className={classes.dialogCloseButton}
            onClick={handleConfirmClose}
          >
            {t("header.reportDialogClose")}
          </Button>
          <Button
            className={classes.dialogCloseButton}
            onClick={handleConfirmExe}
          >
            {t("header.reportDialogExe")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ReportField;
