import React, { useState, useContext } from "react";
import classes from "./OtpSettingField.module.scss";
import { FormGroup, Stack, Switch, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import OptSettingDialog from "../../parts/Dialog/OptSettingDialog.tsx";
import UserContext from "../../../contexts/UserContext.tsx";
import { UserInfo } from "../../../types/response/UserInfoResponse.tsx";
import { useUser } from "../../../hooks/useUser.tsx";

const OtpSettingField = () => {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const userInfo = userContext?.userInfo as UserInfo;
  const [optSwitch, setOptSwtitch] = useState<boolean>(userInfo.mfa_flag);
  const [open, setOpen] = useState<boolean>(false);
  const { putUserApi } = useUser();

  const handleChange = (e) => {
    setOptSwtitch(e.target.checked);
    setOpen(true);
  };

  const handleSetting = () => {
    setOpen(false);
    putUserApi(optSwitch);
  };

  const handleClose = () => {
    setOpen(false);
    setOptSwtitch(userInfo.mfa_flag);
  };

  return (
    <>
      <FormGroup>
        <Stack
          direction="row"
          className={classes.optSwitchLabel}
          spacing={1}
          sx={{ alignItems: "center" }}
        >
          <Typography>Off</Typography>
          <Switch
            className={classes.optSwitch}
            checked={optSwitch}
            onChange={handleChange}
          />
          <Typography>On</Typography>
        </Stack>
      </FormGroup>
      <OptSettingDialog
        open={open}
        handleClose={handleClose}
        handleSetting={handleSetting}
        optSwitch={optSwitch}
      />
    </>
  );
};

export default OtpSettingField;
