import React, { useContext, useEffect } from "react";
import { Box } from "@mui/material";
import AccountCreateForm from "../../forms/AccountCreate/AccountCreateForm.tsx";
import UserContext from "../../../contexts/UserContext.tsx";
import { UserInfo } from "../../../types/response/UserInfoResponse.tsx";
import { useUser } from "../../../hooks/useUser.tsx";
import { useTranslation } from "react-i18next";

type AccountCreateLayoutProps = {};

const AccountCreateLayout: React.FC<AccountCreateLayoutProps> = (props) => {
  const { i18n } = useTranslation();
  const userContext = useContext(UserContext);
  const userInfo = userContext?.userInfo as UserInfo;
  const { putLanguageApi } = useUser();

  useEffect(() => {
    // 言語設定API
    const language = localStorage.getItem("language");
    if (language && userInfo.language !== language) {
      // ログイン画面にて、言語設定が変更されている場合言語設定APIを実行する
      putLanguageApi(language, true);
    } else {
      i18n.changeLanguage(userInfo.language);
      localStorage.setItem("language", userInfo.language);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <AccountCreateForm />
    </Box>
  );
};

export default AccountCreateLayout;
