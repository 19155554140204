// サイドメニューEnumクラス
export enum SideMenuEnum {
  home = "home",
  data_input = "data-input",
  company_parent = "company_parent",
  company = "company",
  product_parent = "product_parent",
  summary = "summary",
  product = "product",
  facility = "facility",
}

// 各種設定画面サイドメニューEnumクラス
export enum SettingSideMenuEnum {
  language = "language",
  security = "security",
  change_password = "change_password",
  setting_opt = "setting_opt",
  access_control = "access_control",
}
