import { useQuery, useMutation } from "react-query";
import axios from "axios";
import UserInfoResponse from "../../types/response/UserInfoResponse";
import UserInfoRequest from "../../types/request/UserInfoRequest";
import LanguageRequest from "../../types/request/LanguageRequest";

const UserApi = {
  /**
   * ユーザー情報取得API
   * @param useId
   */
  GetUserInfo: (userId: string, onSuccess, onError) => {
    return useQuery(
      "getUser",
      async () => {
        const response = await axios.get<UserInfoResponse>(
          process.env.REACT_APP_HOST_URL + "/v1/users/" + userId,
          {
            headers: {
              authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
            withCredentials: true,
          }
        );
        return response.data;
      },
      {
        enabled: false,
        onSuccess: onSuccess,
        onError: onError,
        retry: 0,
      }
    );
  },
  /**
   * ユーザー情報取得API
   * @param useId
   */
  PutUserInfo: (onSuccess, onError) => {
    return useMutation(
      async (request: UserInfoRequest) => {
        const response = await axios.put<UserInfoResponse>(
          process.env.REACT_APP_HOST_URL + "/v1/users/" + request.userId,
          request.requestBody,
          {
            headers: {
              authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
            withCredentials: true,
          }
        );
        return response.data;
      },
      {
        onSuccess: onSuccess,
        onError: onError,
        retry: 0,
      }
    );
  },
  /**
   * 言語設定API
   * @param useId
   */
  PutLanguage: (onSuccess, onError) => {
    return useMutation(
      async (request: LanguageRequest) => {
        const response = await axios.put(
          process.env.REACT_APP_HOST_URL + "/v1/users/language",
          request,
          {
            headers: {
              authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
            withCredentials: true,
          }
        );
        return response.data;
      },
      {
        onSuccess: onSuccess,
        onError: onError,
        retry: 0,
      }
    );
  },
};

export default UserApi;
