import React, { useEffect, useState } from "react";
import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import { FormControl } from "@mui/material";
import { Select, SelectChangeEvent } from "@mui/material";
import { MenuItem } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import classes from "./NoticeCreateForm.module.scss";
import { useTranslation } from "react-i18next";
import { useNotices } from "../../../hooks/useNotices.tsx";
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

const NoticeCreateForm = () => {
  const { t } = useTranslation();
  const { getNoticesApi, registerNoticeApi, categoryList, setNoticeRequest } =
    useNotices();
  const [category, setCategory] = useState<string>("");
  const [releasedAtStr, setReleasedAtStr] = useState<Dayjs | undefined>(
    undefined
  );
  const [releasedAtEnd, setReleasedAtEnd] = useState<Dayjs | undefined>(
    undefined
  );
  const [content, setContent] = useState<string>("");
  const isActive =
    category !== "" && releasedAtStr && releasedAtEnd && content !== ""
      ? false
      : true;

  useEffect(() => {
    getNoticesApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDateStartChange = (date) => setReleasedAtStr(date);
  const onDateEndChange = (date: Dayjs | null) => {
    if (date) {
      const updatedDate = date.hour(23).minute(59).second(59).millisecond(0);
      setReleasedAtEnd(updatedDate);
    }
  };
  const onCategoryChange = (event: SelectChangeEvent) =>
    setCategory(event.target.value);

  const onNoticeContentChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setContent(event.target.value);

  const handleButtonClick = () => {
    registerNoticeApi();
  };

  useEffect(() => {
    if (!releasedAtStr || !releasedAtEnd) return;
    const released_at_str = dayjs(releasedAtStr)
      .utc()
      .format("YYYY-MM-DD HH:mm:ss");
    const released_at_end = dayjs(releasedAtEnd)
      .utc()
      .format("YYYY-MM-DD HH:mm:ss");
    setNoticeRequest({
      released_at_str,
      released_at_end,
      category,
      content,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [releasedAtStr, releasedAtEnd, category, content]);

  return (
    <Stack className={classes.container}>
      <Box className={classes.create_segment}>
        <Typography className={classes.element_title}>
          {t("noticeCreate.releasedAtStr")}
        </Typography>
        <Box className={classes.element_content}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker onChange={onDateStartChange} format="YYYY/MM/DD" />
          </LocalizationProvider>
        </Box>
      </Box>
      <Box className={classes.create_segment}>
        <Typography className={classes.element_title}>
          {t("noticeCreate.releasedAtEnd")}
        </Typography>
        <Box className={classes.element_content}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker onChange={onDateEndChange} format="YYYY/MM/DD" />
          </LocalizationProvider>
        </Box>
      </Box>
      <Box className={classes.create_segment}>
        <Typography className={classes.element_title}>
          {t("noticeCreate.category")}
        </Typography>
        <Box className={classes.element_content}>
          <FormControl variant="standard">
            <Select
              labelId="category-select-box-label"
              id="category-select-box"
              value={category}
              onChange={onCategoryChange}
              className={classes.selectbox}
            >
              {categoryList ? (
                categoryList.map((item) => {
                  return (
                    <MenuItem key={item.code} value={item.code}>
                      {item.name}
                    </MenuItem>
                  );
                })
              ) : (
                <></>
              )}
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Box className={classes.create_segment}>
        <Typography className={classes.element_title}>
          {t("noticeCreate.noticeContent")}
        </Typography>
        <Box className={classes.element_content}>
          <TextField
            id="standard-multiline-flexible"
            multiline
            className={classes.textfield}
            value={content}
            onChange={onNoticeContentChange}
          />
        </Box>
      </Box>
      <Box className={classes.create_segment}>
        <Typography className={classes.element_title}>
          {t("noticeCreate.systemShutdown")}
        </Typography>
        <Box className={classes.element_content}>
          <FormControl variant="standard">
            <Select
              labelId="category-select-box-label"
              id="category-select-box"
              className={classes.selectbox}
            ></Select>
          </FormControl>
        </Box>
      </Box>
      <Button
        variant="contained"
        disabled={isActive}
        onClick={handleButtonClick}
      >
        {t("noticeCreate.register")}
      </Button>
    </Stack>
  );
};

export default NoticeCreateForm;
