import React, { useEffect, useState } from "react";
import { Box, Divider, SelectChangeEvent, Typography } from "@mui/material";
import classes from "./LanguageForm.module.scss";
import { useTranslation } from "react-i18next";
import SelectItem from "../../parts/Select/SelectItem.tsx";
import { Language } from "../../../types/enum/LanguageEnum.tsx";
import { useUser } from "../../../hooks/useUser.tsx";

const LanguageForm = () => {
  const { t, i18n } = useTranslation();
  const [selectLan, setSelectLan] = useState(localStorage.getItem("language"));
  const { putLanguageApi } = useUser();

  const handleSelectLanguage = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    i18n.changeLanguage(value);
    setSelectLan(value);
  };

  // 言語設定API実行
  useEffect(() => {
    const language = localStorage.getItem("language");
    if (selectLan && language !== selectLan) {
      localStorage.setItem("language", selectLan);
      putLanguageApi(selectLan);
    }
  }, [selectLan]);

  return (
    <>
      <Typography variant="h5" component="div" className={classes.title}>
        {t("common.language")}
      </Typography>
      <Divider />
      <Box sx={{ pl: 1, pt: 1 }}>
        <SelectItem
          title=""
          selectValue={selectLan || "ja"}
          values={Language}
          className={classes.language}
          handleClick={handleSelectLanguage}
        />
      </Box>
    </>
  );
};

export default LanguageForm;
