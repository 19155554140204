import React from "react";
import classes from "./OtpSettingForm.module.scss";
import { Box, Divider, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import OtpSettingField from "../../fields/Setting/OtpSettingField.tsx";

const OtpSettingForm = () => {
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="h5" component="div" className={classes.title}>
        {t("setting.otpSetting.title")}
      </Typography>
      <Divider />
      <Box sx={{ pl: 3, pt: 1 }}>
        <OtpSettingField />
      </Box>
    </>
  );
};

export default OtpSettingForm;
