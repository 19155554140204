import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import React from "react";
import classes from "./OptSettingDialog.module.scss";
import { useTranslation } from "react-i18next";

type OptSettingDialogProps = {
  className?: string;
  open: boolean;
  optSwitch: boolean;
  handleClose: () => void;
  handleSetting: () => void;
};

const OptSettingDialog: React.FC<OptSettingDialogProps> = (props) => {
  const { t } = useTranslation();

  const handleSetting = () => {
    props.handleSetting();
  };

  return (
    <Dialog
      className={classes.dialog}
      open={props.open}
      onClose={props.handleClose}
    >
      <DialogContent className={classes.dialogContent}>
        {props.optSwitch && (
          <Typography
            sx={{
              // float: "left",
              mr: 1,
              ml: 1,
            }}
            className={classes.message}
          >
            {t("dialog.optSetting.onConfirmMessage")}
          </Typography>
        )}
        {!props.optSwitch && (
          <Typography
            sx={{
              // float: "left",
              mr: 1,
              ml: 1,
            }}
            className={classes.message}
          >
            {t("dialog.optSetting.offConfirmMessage")}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.button}
          onClick={handleSetting}
          color="primary"
        >
          {t("dialog.optSetting.exeButton")}
        </Button>
        <Button
          className={classes.button}
          onClick={props.handleClose}
          color="secondary"
        >
          {t("dialog.optSetting.cancelButton")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OptSettingDialog;
