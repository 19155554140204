import { useContext, useState } from "react";
import MessageType from "../types/Message.tsx";
import AppContext from "../contexts/AppContext.tsx";
import NoticeApi from "../features/api/NoticeApi.tsx";
import { NoticeInfo } from "../types/response/NoticeResponse.tsx";
import { ApiCommon } from "../features/ApiCommon.tsx";
import NoticeType from "../types/NoticeType.tsx";
import NoticeRequest from "../types/request/NoticeRequest.tsx";
import { useNavigate } from "react-router-dom";
import CategoryType from "../types/CategoryType.tsx";

export const useNotices = () => {
  const Message = MessageType();
  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  const { onCommonError } = ApiCommon();
  const [categoryList, setCategoryList] = useState<CategoryType[]>([]);
  const [noticeList, setNoticeList] = useState<NoticeType[]>([]);
  const [checkedList, setCheckedList] = useState<number[]>([]);
  const [noticeRequest, setNoticeRequest] = useState<NoticeRequest>();

  /**
   * 選択時のハンドリング
   */
  const handleCheck = (id: number) => {
    if (checkedList.includes(id)) {
      setCheckedList(checkedList.filter((item) => item !== id));
    } else {
      setCheckedList([...checkedList, id]);
    }
  };

  /**
   * おしらせ一覧取得API
   */
  const onGetNoticesSuccess = (data: NoticeInfo) => {
    if (!data) {
      appContext?.setErrorOpen(true);
      appContext?.setAlertMessage(Message.ERROR_NO_RESPONSE_DATA);
      return;
    }
    setCategoryList(data.category_list);
    setNoticeList(data.notice_list);
  };

  const onGetNoticesError = (error) => {
    onCommonError(error);
  };

  /**
   * お知らせ削除API
   */
  const onDeleteSuccess = (data) => {
    if (!data) {
      appContext?.setErrorOpen(true);
      appContext?.setAlertMessage(Message.ERROR_NO_RESPONSE_DATA);
      return;
    }
    getNoticesApi();
  };

  const onDeleteError = (error) => {
    onCommonError(error);
  };

  /**
   * お知らせ登録API
   */
  const onRegisterSuccess = (data) => {
    if (!data) {
      appContext?.setErrorOpen(true);
      appContext?.setAlertMessage(Message.ERROR_NO_RESPONSE_DATA);
      return;
    }
    return navigate("/admin/notice-management");
  };

  const onRegisterError = (error) => {
    onCommonError(error);
  };

  /**
   * お知らせ一覧取得
   */
  const getNoticesApi = () => {
    exeGetNotices.refetch();
  };

  /**
   * お知らせ削除
   */
  const deleteNoticeApi = () => {
    exeDeleteNotice.refetch();
  };

  /**
   * お知らせ登録
   */
  const registerNoticeApi = () => {
    exeRegisterNotice.refetch();
  };

  // おしらせ一覧取得API実行
  const exeGetNotices = NoticeApi.GetNotices(
    onGetNoticesSuccess,
    onGetNoticesError
  );

  // おしらせ削除API実行
  const exeDeleteNotice = NoticeApi.DeleteNotice(
    checkedList,
    onDeleteSuccess,
    onDeleteError
  );

  // おしらせ登録API実行
  const exeRegisterNotice = NoticeApi.RegisterNotice(
    noticeRequest,
    onRegisterSuccess,
    onRegisterError
  );

  return {
    getNoticesApi,
    deleteNoticeApi,
    registerNoticeApi,
    categoryList,
    noticeList,
    handleCheck,
    checkedList,
    noticeRequest,
    setNoticeRequest,
  };
};
