import { useTranslation } from "react-i18next";
import SideMenu from "./SideMenu";
import { LanguageEnum } from "./enum/LanguageEnum.tsx";

// 絞り込み条件表示対象サイドメニュー
export const DispSearchItem = [
  "company_parent",
  "company",
  "product_parent",
  "summary",
  "product",
  "facility",
];

type ReportOutput = {
  key: string;
  pageName: string;
  reports: {
    type: string;
    title: string;
    disabled: boolean;
    dispLan: string[]; //空の場合は全表示、値がある場合は、その言語の時のみ表示
  }[];
};

const SideMenusType = () => {
  const { t } = useTranslation();
  const SideMenus: SideMenu[] = [
    {
      id: "home",
      title: t("type.sideMenu.sideMenus.home"),
      children: [],
    },
    {
      id: "data-input",
      title: t("type.sideMenu.sideMenus.data"),
      children: [],
    },
    {
      id: "company_parent",
      title: t("type.sideMenu.sideMenus.companyParent"),
      children: [
        {
          id: "company",
          title: t("type.sideMenu.sideMenus.company"),
        },
        // {
        //   id: "company_free_analysis",
        //   title: t("type.sideMenu.sideMenus.companyFreeAnalysis"),
        // },
      ],
    },
    {
      id: "product_parent",
      title: t("type.sideMenu.sideMenus.productParent"),
      children: [
        {
          id: "summary",
          title: t("type.sideMenu.sideMenus.summary"),
        },
        {
          id: "product",
          title: t("type.sideMenu.sideMenus.product"),
        },
        {
          id: "facility",
          title: t("type.sideMenu.sideMenus.facility"),
        },
        // {
        //   id: "product_free_analysis",
        //   title: t("type.sideMenu.sideMenus.productFreeAnalysis"),
        // },
      ],
    },
    // {
    //   id: "energy_saving",
    //   title: t("type.sideMenu.sideMenus.energySaving"),
    //   children: [],
    // },
    // {
    //   id: "warming_countermeasures",
    //   title: t("type.sideMenu.sideMenus.warmingCountermeasures"),
    //   children: [],
    // },
  ];

  // サイドメニュー毎の帳票出力項目一覧
  const ReportOutputItems: ReportOutput[] = [
    {
      key: "company",
      pageName: t("type.sideMenu.reportOutputItems.company.pageName"),
      reports: [
        {
          type: "data_output",
          title: t("type.sideMenu.reportOutputItems.company.dataOutput"),
          disabled: false,
          dispLan: [],
        },
        {
          type: "energy_saving",
          title: t("type.sideMenu.reportOutputItems.company.checkOutput"),
          disabled: true,
          dispLan: [LanguageEnum.ja],
        },
        {
          type: "registration_data",
          title: t(
            "type.sideMenu.reportOutputItems.company.registrationOutput"
          ),
          disabled: false,
          dispLan: [],
        },
        {
          type: "csv",
          title: t("type.sideMenu.reportOutputItems.company.csv"),
          disabled: false,
          dispLan: [],
        },
        {
          type: "pdf",
          title: t("type.sideMenu.reportOutputItems.company.pdf"),
          disabled: false,
          dispLan: [],
        },
      ],
    },
    {
      key: "summary",
      pageName: t("type.sideMenu.reportOutputItems.summary.pageName"),
      reports: [
        {
          type: "data_output",
          title: t("type.sideMenu.reportOutputItems.summary.dataOutput"),
          disabled: false,
          dispLan: [],
        },
        {
          type: "check_output",
          title: t("type.sideMenu.reportOutputItems.summary.checkOutput"),
          disabled: false,
          dispLan: [],
        },
        {
          type: "csv",
          title: t("type.sideMenu.reportOutputItems.summary.csv"),
          disabled: false,
          dispLan: [],
        },
        {
          type: "pdf",
          title: t("type.sideMenu.reportOutputItems.summary.pdf"),
          disabled: false,
          dispLan: [],
        },
      ],
    },
    {
      key: "product",
      pageName: t("type.sideMenu.reportOutputItems.product.pageName"),
      reports: [
        {
          type: "data_output",
          title: t("type.sideMenu.reportOutputItems.product.dataOutput"),
          disabled: false,
          dispLan: [],
        },
        {
          type: "csv",
          title: t("type.sideMenu.reportOutputItems.product.csv"),
          disabled: false,
          dispLan: [],
        },
        {
          type: "pdf",
          title: t("type.sideMenu.reportOutputItems.product.pdf"),
          disabled: false,
          dispLan: [],
        },
      ],
    },
    {
      key: "facility",
      pageName: t("type.sideMenu.reportOutputItems.facility.pageName"),
      reports: [
        {
          type: "data_output",
          title: t("type.sideMenu.reportOutputItems.facility.dataOutput"),
          disabled: false,
          dispLan: [],
        },
        {
          type: "csv",
          title: t("type.sideMenu.reportOutputItems.facility.csv"),
          disabled: false,
          dispLan: [],
        },
        {
          type: "pdf",
          title: t("type.sideMenu.reportOutputItems.facility.pdf"),
          disabled: false,
          dispLan: [],
        },
      ],
    },
  ];
  return {
    SideMenus,
    ReportOutputItems,
  };
};

export default SideMenusType;
