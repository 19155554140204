import React from "react";
import BaseButton from "../../parts/Button/BaseButton.tsx";
import { Divider, Typography } from "@mui/material";
import classes from "./NewPasswordForm.module.scss";
import CircularProgressDialog from "../../parts/Dialog/CircularProgressDialog.tsx";
import { useTranslation } from "react-i18next";
import NewPasswordField from "../../fields/Setting/NewPasswordField.tsx";
import { usePasswordChange } from "../../../hooks/usePasswordChange.tsx";

const NewPasswordForm = () => {
  const { t } = useTranslation();
  const {
    passwordChangeApi,
    checkInputData,
    password,
    setPassword,
    newPassword,
    setNewPassword,
    reNewPassword,
    setReNewPassword,
    isLoading,
  } = usePasswordChange();

  const updatePassword = () => {
    if (checkInputData()) {
      // パスワード変更API実行
      passwordChangeApi();
    }
  };

  return (
    <>
      {!isLoading && (
        <>
          <Typography variant="h5" component="div" className={classes.title}>
            {t("setting.newPassword.title")}
          </Typography>
          <Divider />
          <div className={classes.newPassword}>
            <NewPasswordField
              password={password}
              setPassword={setPassword}
              newPassword={newPassword}
              setNewPassword={setNewPassword}
              reNewPassword={reNewPassword}
              setReNewPassword={setReNewPassword}
            />
            <BaseButton
              label={t("setting.newPassword.button")}
              className={classes.reset_button}
              handleClick={updatePassword}
            />
          </div>
          <div className={classes.enterCharacters}>
            <Typography
              variant="body1"
              component="div"
              className={classes.explain}
            >
              {t("setting.newPassword.enterCharacters")}
            </Typography>
            <Typography
              variant="body1"
              component="div"
              className={classes.explain}
            >
              {t("setting.newPassword.alphabetNumber")}
            </Typography>
            <Typography
              variant="body1"
              component="div"
              className={classes.explain}
            >
              {t("setting.newPassword.symbol")}
            </Typography>
          </div>
        </>
      )}
      {isLoading && <CircularProgressDialog />}
    </>
  );
};

export default NewPasswordForm;
