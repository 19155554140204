import React, { useContext, useEffect, useRef, useState } from "react";
import classes from "./HeatMapItem.module.scss";
import { HeatMapGrid } from "react-grid-heatmap";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { Fullscreen, FullscreenExit, PhotoCamera } from "@mui/icons-material";
import { Box, Tooltip, Typography } from "@mui/material";
import DatamartPcfHeatmap from "../../../types/response/DatamartPcfHeatmap";
import Color from "../../../types/Color.tsx";
import { ScreenFuatures } from "../../../features/ScreenFuatures.tsx";
import DatamartSummaryContext from "../../../contexts/DatamartSummaryContext.tsx";
import SearchFeatures from "../../../features/SearchFeatures.tsx";
import MasterContext from "../../../contexts/MasterContext.tsx";
import {
  defaultMasterDatas,
  MasterDatas,
} from "../../../types/response/MasterDataResponse.tsx";
import DatamartFacilityContext from "../../../contexts/DatamartFacilityContext.tsx";
import CommonUtils from "../../../common/utils/CommonUtils.tsx";
import { useTranslation } from "react-i18next";

type HeatMapItemProps = {
  title: string;
  data: DatamartPcfHeatmap[];
  type: string;
  category: string;
  emissionValue?: string;
};

const HeatMapItem: React.FC<HeatMapItemProps> = (props) => {
  const { t } = useTranslation();
  const masterContext = useContext(MasterContext);
  const masterData = masterContext?.masterData
    ? (masterContext.masterData as MasterDatas)
    : defaultMasterDatas;
  const handle = useFullScreenHandle();
  const containerRef = useRef(null);
  const [panelHeight, setPanelHeight] = useState("0px");
  const [panelPaddingTop, setPanelPaddingTop] = useState("0px");
  const [heatMap, setHeatMap] = useState<{
    xLabels: Map<string, string>;
    yLabels: Map<string, string>;
    mapData: number[][];
  }>({
    xLabels: new Map(),
    yLabels: new Map(),
    mapData: [],
  });
  const [isHovered, setHovered] = useState(false);
  const { photoSave } = ScreenFuatures();
  const { selectCellByHeatMap } = SearchFeatures();
  const summaryContext = useContext(DatamartSummaryContext);
  const facilityContext = useContext(DatamartFacilityContext);

  useEffect(() => {
    const xLabels: Map<string, string> = new Map();
    const yLabels: Map<string, string> = new Map();
    const mapData: number[][] = [];
    props.data.forEach((data) => {
      if (!yLabels[data.y_axis_code]) {
        yLabels.set(
          data.y_axis_code,
          CommonUtils.dispLabelOmisssion(data.y_axis_key, 8)
        );
      }
      if (!xLabels[data.x_axis_code]) {
        xLabels.set(data.x_axis_code, data.x_axis_key);
      }
    });
    Array.from(yLabels.keys()).forEach((yCodes) => {
      const xDatas: number[] = [];
      Array.from(xLabels.keys()).forEach((xCodes) => {
        const xData = props.data.find((data) => {
          return data.x_axis_code === xCodes && data.y_axis_code === yCodes;
        });
        xDatas.push(xData ? xData.value : 0);
      });
      mapData.push(xDatas);
    });
    setHeatMap({
      xLabels: xLabels,
      yLabels: yLabels,
      mapData: mapData,
    });
  }, [props.data]);

  // 色の条件設定関数
  const getColor = (_x, _y) => {
    const value = heatMap.mapData[_x][_y];
    if (!value) return "#fff";
    const flatArray = heatMap.mapData.flat();
    const sortedArray = [...flatArray].sort((a, b) => b - a);
    const rank = sortedArray.indexOf(value) + 1;
    const maxSize = flatArray.length;

    if (rank <= maxSize / 5) return Color.heatmap[0];
    if (rank <= (maxSize * 2) / 5) return Color.heatmap[1];
    if (rank <= (maxSize * 3) / 5) return Color.heatmap[2];
    if (rank <= (maxSize * 4) / 5) return Color.heatmap[3];
    return Color.heatmap[4];
  };

  const getSelectColor = (_y, _x) => {
    if (
      props.type === "summary" &&
      summaryContext &&
      summaryContext.selectCells.length
    ) {
      const product = Array.from(heatMap.yLabels.keys())[_y];
      const code = Array.from(heatMap.xLabels.keys())[_x];
      if (
        summaryContext.selectCells[0] === product &&
        summaryContext.selectCells[1] === code
      ) {
        return "#85adff";
      }
    } else if (
      props.type === "facility" &&
      facilityContext &&
      facilityContext.selectCells.length
    ) {
      const product = Array.from(heatMap.xLabels.keys())[_x];
      const code = Array.from(heatMap.yLabels.keys())[_y];
      if (
        facilityContext.selectCells[0] === product &&
        facilityContext.selectCells[1] === code
      ) {
        return "#85adff";
      }
    }
    return "#fff";
  };

  const updateCellHeight = () => {
    if (containerRef.current) {
      const containerHeight = containerRef.current.clientHeight;
      const yLabelsLengths = Array.from(heatMap.yLabels.values()).length + 1;
      if (handle.active) {
        setPanelHeight(`${(containerHeight / yLabelsLengths) * 0.8}px`);
        setPanelPaddingTop("0px");
      } else {
        setPanelHeight(`${(containerHeight / yLabelsLengths) * 0.8}px`);
        setPanelPaddingTop("0px");
      }
    }
  };

  useEffect(() => {
    updateCellHeight();
    window.addEventListener("resize", updateCellHeight);
    return () => {
      window.removeEventListener("resize", updateCellHeight);
    };
  }, [heatMap.yLabels, handle.active]);

  /**
   * ヒートマップのセルをクリックした際、Y軸の製品コードにて検索処理を実行
   * @param _y Y軸の要素位置
   * @param _x X軸の要素位置
   */
  const handleClick = (_y: number, _x: number) => {
    if (props.type === "summary" && summaryContext) {
      const product = Array.from(heatMap.yLabels.keys())[_y];
      const code = Array.from(heatMap.xLabels.keys())[_x];
      if (code === "all") return;
      summaryContext.setSelectCells([product, code]);
      selectCellByHeatMap(product, code, props.category, masterData);
    } else if (props.type === "facility") {
      const product = Array.from(heatMap.xLabels.keys())[_x];
      const code = Array.from(heatMap.yLabels.keys())[_y];
      if (product === "all") return;
      facilityContext.setSelectCells([product, code]);
      selectCellByHeatMap(product, code, props.category, masterData);
    }
  };

  const handleBoxClick = (event) => {
    const elmBtn = document.getElementById("heatMapButton_" + props.type);
    if (
      event.target.classList &&
      (!event.target.classList.length ||
        !event.target.classList.contains("heatMapItemCell")) &&
      elmBtn &&
      !elmBtn.contains(event.target)
    ) {
      if (props.type === "summary" && summaryContext) {
        if (summaryContext.selectCells.length) {
          summaryContext.setSelectCells([]);
          selectCellByHeatMap("", "all", props.category, masterData);
        }
      } else if (props.type === "facility" && facilityContext) {
        if (facilityContext.selectCells.length) {
          facilityContext.setSelectCells([]);
          selectCellByHeatMap("", "all", props.category, masterData);
        }
      }
    }
  };

  const dispToolTip = (_y: number, _x: number, value) => {
    let product = "";
    let code = "";
    let valueItem = t("parts.heatMap.tooltipPCF");
    if (props.type === "summary" && summaryContext) {
      product = Array.from(heatMap.yLabels.values())[_y];
      code = Array.from(heatMap.xLabels.values())[_x];
    } else if (props.type === "facility") {
      product = Array.from(heatMap.xLabels.values())[_x];
      code = Array.from(heatMap.yLabels.values())[_y];
      if (props.emissionValue !== "pcf") {
        valueItem = t("parts.heatMap.tooltipCO2");
      }
    }

    return (
      <div
        className={classes.tooltip}
        style={{
          borderColor: getColor(_y, _x),
        }}
      >
        <p>{product}</p>
        <p>{code}</p>
        <p>{`${valueItem}:${value ? value.toLocaleString() : value}`}</p>
      </div>
    );
  };

  const dispCellRender = (value: string | number) => {
    let fontSize = 2;
    if (value) {
      const size = value.toString().length;
      if (size > 13) {
        fontSize = fontSize - (size - 13) / 5;
      }
    }
    return (
      <div
        className={`${classes.heatMapCell} heatMapItemCell`}
        style={{ fontSize: `${fontSize}vh` }}
      >
        {value}
      </div>
    );
  };

  return (
    <div
      className={classes.heatMap}
      onClick={handleBoxClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <FullScreen handle={handle} className={classes.heatMap}>
        <Box
          ref={containerRef}
          className={
            handle.active ? classes.heatMapItemActive : classes.heatMapItem
          }
        >
          {handle.active && (
            <Typography
              sx={{
                textAlign: "left",
              }}
              color="text.secondary"
              gutterBottom
              className={classes.title}
            >
              {props.title}
            </Typography>
          )}
          <Box
            id={"heatMap_" + props.type}
            className={classes.screenHeatMap}
            sx={{ width: handle.active ? "99%" : "100%" }}
          >
            <HeatMapGrid
              xLabels={Array.from(heatMap.xLabels.values())}
              yLabels={Array.from(heatMap.yLabels.values())}
              data={heatMap.mapData}
              cellStyle={(_x, _y) => ({
                background: getColor(_x, _y),
                borderColor: getSelectColor(_x, _y),
                borderTopWidth: "0.3vh",
                borderLeftWidth: "0.3vh",
                borderRightWidth: "0.3vh",
                borderBottomWidth: "0.3vh",
                width: "7vw",
                height: panelHeight,
              })}
              xLabelsStyle={() => ({
                width: "7vw",
                fontSize: "2vh",
              })}
              yLabelsStyle={() => ({
                paddingTop: panelPaddingTop,
                height: panelHeight,
                width: handle.active ? "13vw" : "11vw",
                fontSize: "2vh",
                lineHeight: "none",
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              })}
              xLabelsPos="bottom" // X軸ラベルを下側に表示する
              onClick={handleClick}
              cellRender={(x, y, value) => (
                <Tooltip title={dispToolTip(x, y, value)}>
                  {dispCellRender(value)}
                </Tooltip>
              )}
            />
          </Box>
          <Box
            sx={{ bottom: handle.active ? 0 : 0 }}
            className={classes.buttonDiv}
            id={"heatMapButton_" + props.type}
          >
            {isHovered && (
              <div>
                <div
                  onClick={() =>
                    photoSave(
                      document.getElementById("heatMap_" + props.type),
                      props.title,
                      handle.active
                    )
                  }
                  className={classes.screenButton}
                >
                  <PhotoCamera />
                </div>
                {!handle.active ? (
                  <div onClick={handle.enter} className={classes.fullscreen}>
                    <Fullscreen />
                  </div>
                ) : (
                  <div onClick={handle.exit} className={classes.fullscreenExit}>
                    <FullscreenExit />
                  </div>
                )}
              </div>
            )}
          </Box>
        </Box>
      </FullScreen>
    </div>
  );
};

export default HeatMapItem;
