import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import classes from "./UserContractInfoField.module.scss";
import UserContext from "../../../contexts/UserContext.tsx";
import { UserInfo } from "../../../types/response/UserInfoResponse.tsx";
import { useTranslation } from "react-i18next";
import { UserFuatures } from "../../../features/UserFuatures.tsx";
import Contract from "../../../types/response/Contract.tsx";

const UserContractInfoField = () => {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const userInfo = userContext?.userInfo as UserInfo;
  const { getUserName } = UserFuatures();
  const [contracts, setContracts] = useState<Contract[]>([]);

  /**
   * 初期表示時、顧客情報一覧作成
   * 顧客IDをキーに重複削除
   */
  useEffect(() => {
    if (userInfo.contracts) {
      setContracts(
        Array.from(
          new Map(
            userInfo.contracts.map((contract) => [
              contract.organization_id,
              contract,
            ])
          ).values()
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Paper elevation={1} className={classes.userContract}>
      <Typography variant="body1" component="div" className={classes.title}>
        {`${getUserName()}${t("top.contract.title")}`}
      </Typography>
      <Divider />
      <Box className={classes.userInfo}>
        <TableContainer className={classes.userInfoTable}>
          <Table
            size="small"
            sx={{ minWidth: 500, tableLayout: "fixed", width: "65vw" }}
          >
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: "13.5%" }}>
                  {t("top.contract.plan")}
                </TableCell>
                <TableCell sx={{ width: "15.3%" }}>
                  {t("top.contract.cutomer")}
                </TableCell>
                <TableCell sx={{ width: "13.5%" }}>
                  {t("top.contract.company")}
                </TableCell>
                <TableCell sx={{ width: "13.5%" }}>
                  {t("top.contract.startDate")}
                </TableCell>
                <TableCell sx={{ width: "13.5%" }}>
                  {t("top.contract.endDate")}
                </TableCell>
                <TableCell sx={{ width: "15.3%" }}>
                  {t("top.contract.model")}
                </TableCell>
                <TableCell sx={{ width: "15.3%" }}>
                  {t("top.contract.contract")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {contracts.map((contract) => {
                return (
                  <TableRow>
                    <TableCell
                      sx={{ width: "13.5%" }}
                      className={classes.tableCell}
                    >
                      {contract.plan_name}
                    </TableCell>
                    <TableCell
                      sx={{ width: "15.3%" }}
                      className={classes.tableCell}
                    >
                      {contract.organization_id}
                    </TableCell>
                    <TableCell
                      sx={{ width: "13.5%" }}
                      className={classes.tableCell}
                    >
                      {contract.company_name}
                    </TableCell>
                    <TableCell
                      sx={{ width: "13.5%" }}
                      className={classes.tableCell}
                    >
                      {contract.start_date}
                    </TableCell>
                    <TableCell
                      sx={{ width: "13.5%" }}
                      className={classes.tableCell}
                    >
                      {contract.end_date}
                    </TableCell>
                    <TableCell
                      sx={{ width: "15.3%" }}
                      className={classes.tableCell}
                    >
                      {contract.model_name}
                    </TableCell>
                    <TableCell
                      sx={{ width: "15.3%" }}
                      className={classes.tableCell}
                    >
                      {contract.parent_contract_company_name}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Paper>
  );
};

export default UserContractInfoField;
