import { useQuery } from "react-query";
import axios from "axios";
import { LoginInfo } from "../../types/response/LoginResponse";

const LoginApi = {
  /**
   * ログインAPI
   * @param id
   * @param password
   */
  Login: (
    id: string,
    password: string,
    language: string,
    onSuccess,
    onError
  ) => {
    return useQuery(
      "login",
      async () => {
        const response = await axios.post<LoginInfo>(
          process.env.REACT_APP_HOST_URL + "/v1/auth/login",
          {
            login_id: id,
            password: password,
            language: language,
          }
        );
        return response;
      },
      {
        enabled: false,
        onSuccess: onSuccess,
        onError: onError,
        retry: 0,
      }
    );
  },

  /**
   * 2段階認証ログインAPI
   * @param id
   * @param otp
   */
  OtpLogin: (id: string, otp: string, onSuccess, onError) => {
    return useQuery(
      "otpLogin",
      async () => {
        const response = await axios.post(
          process.env.REACT_APP_HOST_URL + "/v1/auth/login/otp",
          {
            login_id: id,
            otp: otp,
          },
          {
            withCredentials: true,
          }
        );
        return response;
      },
      {
        enabled: false,
        onSuccess: onSuccess,
        onError: onError,
        retry: 0,
      }
    );
  },

  /**
   * ログアウトAPI
   */
  Logout: (onSuccess, onError) => {
    return useQuery(
      "Logout",
      async () => {
        const response = await axios.delete(
          process.env.REACT_APP_HOST_URL + "/v1/auth/logout",
          {
            headers: {
              authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
            withCredentials: true,
          }
        );
        return response;
      },
      {
        enabled: false,
        onSuccess: onSuccess,
        onError: onError,
        retry: 0,
      }
    );
  },
};

export default LoginApi;
