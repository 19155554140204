import { useContext, useState } from "react";
import UserApi from "../features/api/UserApi.tsx";
import MessageType from "../types/Message.tsx";
import AppContext from "../contexts/AppContext.tsx";
import UserContext from "../contexts/UserContext.tsx";
import UserInfoRequest from "../types/request/UserInfoRequest.tsx";
import { ApiCommon } from "../features/ApiCommon.tsx";
import LanguageRequest from "../types/request/LanguageRequest.tsx";
import { UserInfo } from "../types/response/UserInfoResponse.tsx";

export const useUser = () => {
  const Message = MessageType();
  const appContext = useContext(AppContext);
  const userContext = useContext(UserContext);
  const userInfo = userContext?.userInfo as UserInfo;
  const { onCommonError } = ApiCommon();
  const [userApiLoading, setUserApiLoading] = useState(false);
  let mfaFlag = false;

  /**
   * ユーザー情報取得API成功時
   */
  const onSuccess = (data) => {
    if (!data) {
      appContext?.setErrorOpen(true);
      appContext?.setAlertMessage(Message.ERROR_NO_RESPONSE_DATA);
      return;
    }
    userContext?.setUserInfo(data);
    setUserApiLoading(false);
  };

  /**
   * ユーザー情報取得API失敗時
   */
  const onError = (error) => {
    onCommonError(error);
    setUserApiLoading(false);
  };

  /**
   * ユーザー情報取得
   */
  const getUserApi = () => {
    setUserApiLoading(true);
    exeGetUser.refetch();
  };

  /**
   * ユーザー情報更新API成功時
   */
  const onPutUserSuccess = (data) => {
    userInfo.mfa_flag = mfaFlag;
    userContext?.setUserInfo(Object.assign({}, userInfo));
    appContext?.setSuccessOpen(true);
    appContext?.setAlertMessage(Message.SUCCESS_PUT_OTP);
    setUserApiLoading(false);
  };

  /**
   * ユーザー情報更新
   */
  const putUserApi = (mfa_flag: boolean) => {
    if (!appContext?.idToken.sub) {
      appContext?.setErrorOpen(true);
      appContext?.setAlertMessage(Message.ERROR_SYSTEM_ERROR);
      return;
    }
    const request: UserInfoRequest = {
      userId: appContext.idToken.sub,
      requestBody: {
        mfa_flag: mfa_flag,
      },
    };
    mfaFlag = mfa_flag;
    setUserApiLoading(true);
    exePutUser.mutate(request);
  };

  /**
   * 言語設定API成功時
   */
  const onLanguageSuccess = (data, variables: LanguageRequest) => {
    setUserApiLoading(false);
    console.log(variables);
    userInfo.language = variables.language;
    userContext?.setUserInfo(userInfo);
    appContext?.setAlertMessage(Message.SUCCESS_PUT_LANGUAGE);
    appContext?.setSuccessOpen(true);
  };

  /**
   * 言語設定API成功時(TOP画面専用)
   */
  const onLanguageTopSuccess = (data, variables: LanguageRequest) => {
    setUserApiLoading(false);
    userInfo.language = variables.language;
    userContext?.setUserInfo(userInfo);
  };

  /**
   * 言語設定
   */
  const putLanguageApi = (language: string, isTop?: boolean) => {
    if (!appContext?.idToken.sub) {
      appContext?.setErrorOpen(true);
      appContext?.setAlertMessage(Message.ERROR_SYSTEM_ERROR);
      return;
    }
    const param: LanguageRequest = {
      language: language,
    };
    setUserApiLoading(true);
    if (isTop) {
      exePutTopLanguage.mutate(param);
    } else {
      exePutLanguage.mutate(param);
    }
  };

  // ユーザー情報取得API実行
  const exeGetUser = UserApi.GetUserInfo(
    appContext?.accessToken.sub || "",
    onSuccess,
    onError
  );

  // ユーザー情報更新API実行
  const exePutUser = UserApi.PutUserInfo(onPutUserSuccess, onError);

  // 言語設定API実行
  const exePutLanguage = UserApi.PutLanguage(onLanguageSuccess, onError);

  // 言語設定API実行(TOP画面専用)
  const exePutTopLanguage = UserApi.PutLanguage(onLanguageTopSuccess, onError);

  return {
    getUserApi,
    putUserApi,
    userApiLoading,
    putLanguageApi,
  };
};
