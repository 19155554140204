import { useContext } from "react";
import UserContext from "../contexts/UserContext.tsx";
import { UserInfo } from "../types/response/UserInfoResponse.tsx";
import { FileTypeEnum, FileTypes_Ja } from "../types/enum/FileTypeEnum.tsx";
import { useTranslation } from "react-i18next";
import FileType from "../types/FileType.tsx";

// データ入力画面用処理
const DataInputFeatures = () => {
  const userContext = useContext(UserContext);
  const userInfo = userContext?.userInfo as UserInfo;
  const { i18n } = useTranslation();

  /**
   * プライマリ契約ありユーザー判定処理
   * @returns true プライマリ契約あり false プライマリ契約なし
   */
  const isPrimaryUser = () => {
    if (
      userInfo &&
      userInfo.contracts &&
      userInfo.contracts.length &&
      userInfo.contracts.find((contract) => {
        return contract.plan === "プライマリ";
      })
    ) {
      return true;
    }
    return false;
  };

  /**
   * 顧客情報複数有無確認処理
   * @param isMultiContract
   * @returns true 複数顧客あり false 複数顧客なし
   */
  const isCutomerData = (isMultiContract: boolean) => {
    const cutomer: string[] = [];
    if (!userInfo.contracts || !userInfo.contracts.length) return false;
    userInfo.contracts
      .filter((val) => {
        return userInfo.email === val.email;
      })
      .forEach((value) => {
        if (!isMultiContract && !cutomer.includes(value.organization_id)) {
          cutomer.push(value.organization_id);
        } else if (
          isMultiContract &&
          value.plan === "プライマリ" &&
          !cutomer.includes(value.organization_id)
        ) {
          cutomer.push(value.organization_id);
        }
      });
    if (cutomer.length >= 1) {
      return true;
    }
    return false;
  };

  /**
   * 顧客ID取得処理
   * ※顧客IDが1つの場合のみ
   * @returns
   */
  const getOrganizationId = () => {
    if (!userInfo.contracts || !userInfo.contracts.length) return "";
    const contract = userInfo.contracts[0];
    return contract.organization_id;
  };

  /**
   * 顧客リスト作成
   * 下記条件に該当するデータ作成
   * ・単一顧客の場合
   *  ユーザーのメールアドレスと顧客のメールアドレスが一致している
   *  ※顧客リストの際は、IDが重複しないようにする
   * ・複数顧客の場合
   *  ユーザーのメールアドレスと顧客のメールアドレスが一致している
   *  プライマリ契約である
   *  ※顧客リストの際は、IDが重複しないようにする
   * @param isMultiContract
   * @returns
   */
  const getCutomerList = (isMultiContract: boolean) => {
    const cutomers: {
      value: string;
      label: string;
    }[] = [];
    const findCutomer = (organization_id) => {
      return cutomers.find((val) => {
        return val.value === organization_id;
      });
    };

    if (!userInfo.contracts || !userInfo.contracts.length) return cutomers;

    userInfo.contracts
      .filter((val) => {
        return userInfo.email === val.email;
      })
      .forEach((value) => {
        if (!isMultiContract && !findCutomer(value.organization_id)) {
          cutomers.push({
            value: value.organization_id,
            label: value.company_name,
          });
        } else if (
          isMultiContract &&
          value.plan === "プライマリ" &&
          !findCutomer(value.organization_id)
        ) {
          cutomers.push({
            value: value.organization_id,
            label: value.company_name,
          });
        }
      });
    return cutomers;
  };

  /**
   * 日付選択項目表示判定
   * @param type
   * @returns
   */
  const isDispDate = (type) => {
    if (
      FileTypeEnum.base_unit === type ||
      FileTypeEnum.activity_amount === type
    ) {
      return true;
    }
    return false;
  };

  /**
   * 日本語設定の場合に、表示可能な項目のみにリストを生成
   */
  const dispJaItem = (files: FileType[]) => {
    return files.filter((file) => {
      return (
        file.dispLan.length === 0 || file.dispLan.includes(userInfo.language)
      );
    });
  };

  return {
    isPrimaryUser,
    isCutomerData,
    getOrganizationId,
    getCutomerList,
    isDispDate,
    dispJaItem,
  };
};

export default DataInputFeatures;
